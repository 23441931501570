var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showThis
    ? _c(
        "section",
        { staticClass: "clearfix" },
        [
          _c("hr", { staticClass: "pb-0 mb-0 mt-2 mb-2" }),
          _c(
            "a",
            {
              staticClass: "clickable float-right",
              on: {
                click: function ($event) {
                  _vm.show = true
                },
              },
            },
            [
              _c("i", { staticClass: "fal fa-info-circle" }),
              _vm._v(" " + _vm._s(_vm.$t("DETAILS"))),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                centered: "",
                title: _vm.$t("MESSAGE_DETAILS"),
                "ok-only": "",
                "ok-title": _vm.$t("CLOSE"),
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c("div", { staticClass: "px-3 py-2" }, [
                _c("h6", { staticClass: "mb-0 no-uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("MESSAGE_UUID"))),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.information.message.message_uuid) +
                    "\n\n      "
                ),
                _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("CREATOR_UUID"))),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.information.message.creator.user_uuid) +
                    "\n\n      "
                ),
                _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("MESSAGE_VERSION"))),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.information.message.version) +
                    "\n\n      "
                ),
                _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("LOA.TITLE_PARTICIPANT")) +
                      "\n      "
                  ),
                ]),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fal fa-info-square",
                  attrs: {
                    title: _vm.$t(
                      "LOA.INFO." + _vm.information.message.settings.loa_level
                    ),
                  },
                }),
                _vm._v(
                  "\n      LOA" +
                    _vm._s(_vm.information.message.settings.loa_level) +
                    "\n\n      "
                ),
                _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("TYPE"))),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "MESSAGES.TYPE." + _vm.information.message.message_type
                      )
                    ) +
                    "\n\n      "
                ),
                _vm.information.message.settings.require_response
                  ? _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("RESPONSE")) + "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm.information.message.settings.require_response
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.$t("MESSAGES.REQUESTED_RESPONSE"))),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }