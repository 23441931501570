<template>
  <section>
    <div v-if="!loading">
      <div v-if="information.message">
        <h4 class="m-0 p-0 mt-2 mb-3  link-header">
          <span v-html="information.message.subject"></span>
        </h4>
        <h4 class="m-0 p-0 mt-2 mb-1  link-header">
          {{ $t("ORGANISER") }}
        </h4>
        
        
        <UserInfo
          image="/img/default-avatar.png"
          :primary="createdName"
          :secondary="information.message.creator.email"
          :optional="createdTime"
        ></UserInfo>

        <hr class="pb-0 mb-0 mt-2" />

        <div class="d-flex">
          <div style="width: 12rem;" class="ml-0 pl-0">
            <h4 class="m-0 p-0 mt-2 mb-1  link-header">{{ $t("FROM") }}</h4>
            {{
              FormatDateTime(information.message.settings.meeting_start_date)
            }}
          </div>
          <div style="width: 12rem;" class="ml-0 pl-0">
            <h4 class="m-0 p-0 mt-2 mb-1  link-header">{{ $t("TO") }}</h4>
            {{
              FormatDateTime(information.message.settings.meeting_end_date)
            }}
          </div>
        </div>
        
    <hr class="pb-0 mb-0 mt-2" v-if="recurrence" />

    <PickRecurrence
      class="mt-2"
      v-if="recurrence"
      :recurrence="recurrence"
      :startDate="message.settings.meeting_start_date"
      :endDate="message.settings.meeting_end_date"
      text-only
    ></PickRecurrence>

        <hr class="pb-0 mb-0 mt-2" />

        <div class="mt-2" v-if="attachments.length != 0">
          <span
            v-for="(item, index) in attachments"
            :key="index"
            class="ms-fontSize-12"
          >
            <Attachment :link_uuid="link_uuid" :item="item" :index="index"></Attachment>
          </span>
        </div>

        <div
          v-if="
            information.auth_message != 'open_to_date' &&
              information.auth_message != 'open_from_date'
          "
        >
          <h4 class="m-0 p-0 mt-2 mb-1  link-header">
            {{ $t("DESCRIPTION") }}
          </h4>

          <BodyText :body="information.message.body"></BodyText>

          <hr class="pb-0 mb-2 mt-2" />

          <b-button variant="primary" class="btn-fill" @click="openMeeting">
            {{ $t("SECURELINK.JITSI.JOIN_MEETING") }}
          </b-button>
          
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PickRecurrence from "../../Input/PickRecurrence";
import UserInfo from "@/components/Layout/UserInfo";
import Attachment from "@/components/View/Attachment";
import BodyText from "@/components/View/BodyText";
export default {
  components: {
    UserInfo,
    Attachment,
    BodyText,
    PickRecurrence
  },
  props: ["link_uuid", "open_time", "loading", "information", "authenticated"],
  data() {
    return {};
  },
  methods: {
    openMeeting()
    {

    },
    closeBtn() {
      this.$emit("closeBtn");
    },
  },
  computed: {
    attachments() {
      return this.information.message.attachments.filter((attachment) => {
        return attachment.name != "join_url.txt";
      });
    },
    join_url() {
      let join_attachment = this.information.message.attachments.filter(
        (attachment) => {
          return attachment.name == "join_url.txt";
        }
      );
      if (join_attachment.length == 1) {
        return join_attachment[0].content;
      } else {
        return null;
      }
    },
    createdTime() {
      return this.FormatDateTime(this.information.message.created_date);
    },
    createdName() {
      return (
        this.information.message.creator.name +
        ", " +
        this.information.message.creator.organisation_name
      );
    },
  },
  mounted: function() {},
};
</script>
<style></style>
